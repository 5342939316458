<template>
  <div class="w-full">
    <BookingSummary
      :data="selectedService"
      :has-membership="hasMembership"
      :location="location"
      @change-date="handleChangeDate" />
    <BookingMembershipInfo
      v-if="shouldShowMembershipInfoButtons"
      :department-title="props.departmentTitle"
      :price="service?.price"
      @handle-membership-selection="handleMembershipSelection" />
    <template v-if="bookingStore.hasAppointmentDateSelected === false">
      <ClientOnly>
        <LazyBookingSlotPicker
          v-if="bookingId"
          class="mt-4 overflow-hidden rounded-lg border border-brick-400 pt-2"
          :service-ids="[serviceId]"
          :location-id="location?.attributes.mobimedId"
          :health-professional-booking-id="bookingId"
          @slot-selected="handleSlotSelected" />
        <template #fallback> Lade Buchungskalender…</template>
      </ClientOnly>
    </template>
    <LazyBookingForm
      v-if="bookingStore.hasAppointmentDateSelected && bookingId !== undefined"
      :service-id="serviceId"
      :booking-id="bookingId" />
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import type { BookingDetailsProps } from "~/components/booking/Summary.vue";
import { useBookingStore } from "~/store/booking";
import type { StoredLocation } from "~/stores/location";
import { amedKey, membershipBenefitsKey } from "~/types/ProvideKeys";
import type { Slot } from "~/types/Slot";

const props = defineProps<{
  bookingId?: number;
  serviceId: number;
  serviceName: string;
  reimbursement?: number | string | null;
  departmentTitle?: string;
  location?: StoredLocation;
}>();

const emits = defineEmits<{
  changeDate: [];
  handleMembershipSelection: [value: boolean];
}>();

const bookingStore = useBookingStore();
const { service } = useSchedulerServices(props.serviceId);

const selectedService = computed<BookingDetailsProps>(() => ({
  departmentTitle: props.departmentTitle,
  reimbursement: props.reimbursement,
  service: service.value,
  serviceId: props.serviceId,
  serviceName: props.serviceName,
}));
const hasMembershipBenefits = inject(membershipBenefitsKey);
const isAMed = inject(amedKey);

const shouldShowMembershipInfoButtons = computed(
  () =>
    bookingStore.hasAppointmentDateSelected &&
    props.bookingId !== undefined &&
    hasMembershipBenefits !== false &&
    isAMed === false,
);

const hasMembership = ref<boolean>(false);

function handleSlotSelected(slot: Slot): void {
  bookingStore.setHealthProfessionalDate(slot.date);
  bookingStore.setLocation(slot.location);
}

async function handleChangeDate(): Promise<void> {
  emits("changeDate");
}

function handleMembershipSelection(value: boolean) {
  hasMembership.value = value;
}
</script>

<style lang="scss" scoped></style>
