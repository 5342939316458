<template>
  <div class="mb-6 rounded-2xl bg-gray-50 px-4 py-3 md:px-6 md:py-4">
    <div class="mb-1 flex items-center justify-between gap-x-1 text-sm text-gray-900">
      <template v-if="hasTherapyUpgrade">
        <span class="font-medium text-gray-900">Bist du bereits Plus-Mitglied?</span>
      </template>
      <template v-else-if="hasPediatricsAbo">
        <span class="font-medium text-gray-900">Ist dein Kind bereits Mitglied?</span>
      </template>
      <template v-else>
        <span class="font-medium text-gray-900">Bist du bereits Mitglied?</span>
      </template>
      <AppButton
        class="shrink-0 bg-white !px-2 !py-2 !text-xs font-medium md:!px-4"
        variant="outline"
        @click="membershipDrawerStore.open(numberPrice, discount, departmentTitle)">
        Vorteile ansehen
        <SvgoExternalLink class="ml-1 text-base" />
      </AppButton>
    </div>
    <div class="mt-2">
      <fieldset>
        <legend class="sr-only">haelsi Mitgliedschaft</legend>
        <div class="flex items-center gap-x-2">
          <AppRadioBox
            v-model="hasMembership"
            class="flex-1 bg-white"
            :value="true"
            name="hasMembership"
            data-track-booking-form-membership="true">
            <p class="flex items-center justify-between">
              <span>Ja</span>
              <AppPill v-if="hasDiscount" class="bg-green-100 font-medium text-green-700">
                <span class="md:hidden">&#045; {{ discount }} &percnt;</span>
                <span class="hidden md:block">{{ discount }} &percnt; Rabatt</span>
              </AppPill>
            </p>
          </AppRadioBox>
          <AppRadioBox
            v-model="hasMembership"
            class="flex-1 bg-white"
            :value="false"
            name="hasMembership"
            data-track-booking-form-membership="false">
            Nein
          </AppRadioBox>
        </div>
      </fieldset>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, watch } from "vue";

const props = defineProps<{
  departmentTitle?: string;
  price?: string;
}>();

const emits = defineEmits<{
  handleMembershipSelection: [value: boolean];
}>();

const membershipDrawerStore = useMembershipDrawerStore();

const numberPrice = computed(() => (props.price ? parseInt(props.price, 10) : undefined));

const hasMembership = ref<boolean>(true);

const discount = computed(() => getDiscountForDepartment(props.departmentTitle));
const hasDiscount = computed(() => discount.value !== 0);

const hasTherapyUpgrade = computed(() => hasHigherDiscount(props.departmentTitle));
const hasPediatricsAbo = computed(() => isPediatricsDepartment(props.departmentTitle));

watch(
  hasMembership,
  (newValue) => {
    return emits("handleMembershipSelection", newValue);
  },
  {
    immediate: true,
  },
);
</script>

<style scoped></style>
