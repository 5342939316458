<template>
  <div class="mb-2 rounded-2xl border border-brick-400 bg-brick-100 p-4 md:p-6">
    <div class="pb-2 lg:pb-0">
      <p class="w-max rounded-md bg-lavender-400 px-4 py-1 text-xs leading-tight">{{ data.departmentTitle }}</p>
      <h2 class="text-md text-gray-900 md:text-xl">{{ data.serviceName }}</h2>
    </div>

    <div
      v-show="!bookingStore.hasAppointmentDateSelected"
      id="booking-price-details"
      data-accordion="collapsed"
      data-active-classes="text-gray-900"
      data-inactive-classes="text-gray-500">
      <h2>
        <button
          type="button"
          class="flex w-full items-center gap-3 py-2 text-sm text-gray-700 hover:text-yellow-700 rtl:text-right"
          data-accordion-target="#booking-price-details-body"
          aria-expanded="false"
          aria-controls="booking-price-details-body">
          <span>Info und Preis</span>
          <span>
            <SvgoChevronRight class="h-4 w-4 rotate-90 md:h-5 md:w-5" />
          </span>
        </button>
      </h2>
      <div id="booking-price-details-body" class="hidden">
        <div>
          <div class="mb-4 text-sm text-gray-700" v-html="data.service?.description"></div>
          <div v-if="hasPrice" class="flex flex-row items-stretch justify-between gap-x-2 md:flex-row lg:mt-2">
            <BookingPriceBox
              class="flex-grow"
              :has-membership="hasMembership"
              :service-id="data.serviceId"
              :department-title="data.departmentTitle"
              :price="data.service?.price" />
            <BookingReimbursementBox v-if="hasReimbursement" class="flex-grow" :reimbursement="data.reimbursement" />
          </div>
        </div>
      </div>
    </div>

    <div v-if="bookingStore.hasAppointmentDateSelected" class="mt-2">
      <div class="mb-4 text-sm text-gray-700" v-html="data.service?.description"></div>
      <div v-if="hasPrice" class="flex flex-row items-stretch justify-between gap-x-2 md:flex-row lg:mt-2">
        <BookingPriceBox
          class="flex-grow"
          :has-membership="hasMembership"
          :service-id="data.serviceId"
          :department-title="data.departmentTitle"
          :price="data.service?.price" />
        <BookingReimbursementBox v-if="hasReimbursement" class="flex-grow" :reimbursement="data.reimbursement" />
      </div>
    </div>

    <BookingAppointmentInformation
      v-if="
        bookingStore.hasAppointmentDateSelected &&
        data.service?.duration !== undefined &&
        bookingStore.selectedHealthProfessionalDate !== null
      "
      class="mt-2"
      :date="bookingStore.selectedHealthProfessionalDate"
      :duration="data.service.duration"
      @change="handleDateChange" />

    <div v-if="location" class="mt-4">
      <p class="text-sm text-gray-700">Standort</p>
      <p class="font-medium">
        {{ location.attributes.name }},
        <span class="text-nowrap">{{ location.attributes.address.street_with_number }}</span
        >, {{ location.attributes.address.postal_code }}&nbsp;{{ location.attributes.address.city }}
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { initAccordions } from "flowbite";
import { computed, onMounted } from "vue";
import { useBookingStore } from "~/store/booking";
import type { StoredLocation } from "~/stores/location";
import type { SchedulerService } from "~/types/SchedulerService";

export type BookingDetailsProps = {
  departmentTitle?: string;
  serviceName: string;
  serviceId: number;
  service: SchedulerService | null;
  reimbursement?: number | string | null;
};

const props = withDefaults(
  defineProps<{
    data: BookingDetailsProps;
    hasMembership: boolean;
    location?: StoredLocation;
  }>(),
  {
    hasMembership: false,
  },
);

const emits = defineEmits<{
  changeDate: [];
}>();

onMounted(() => {
  initAccordions();
});

const bookingStore = useBookingStore();

const isFluVaccinationService = isFluVaccinationId(props.data.serviceId);
const hasPrice = computed(() => props.data.service?.price && parseFloat(props.data.service.price) > 0);

const hasReimbursement = computed(() => {
  return (
    !isFluVaccinationService &&
    isNotNullOrUndefined(props.data.reimbursement) &&
    props.data.departmentTitle !== undefined
  );
});

function handleDateChange() {
  emits("changeDate");
}
</script>

<style scoped>
button[aria-expanded="true"] svg {
  @apply -rotate-90;
}
</style>
