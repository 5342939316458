const FLU_VACCINATION_SERVICE_FOR_PEOPLE_LOWER_THAN_65 = 5722;
const FLU_VACCINATION_SERVICE_FOR_PEOPLE_65_AND_OLDER = 5732;

export function isFluVaccinationServiceLowerThan65(id: number): boolean {
  return FLU_VACCINATION_SERVICE_FOR_PEOPLE_LOWER_THAN_65 === id;
}

export function isFluVaccinationService65AndOlder(id: number): boolean {
  return FLU_VACCINATION_SERVICE_FOR_PEOPLE_65_AND_OLDER === id;
}

export function isFluVaccinationId(id: number): boolean {
  return isFluVaccinationServiceLowerThan65(id) || isFluVaccinationService65AndOlder(id);
}
