<template>
  <div>
    <NuxtLayout name="two-columns">
      <UiSpinner v-if="pending" />
      <template v-else>
        <BookingSearchBackButton :default-to="defaultBack" />
        <BlockHealthProfessionalSection
          v-if="healthProfessional"
          :health-professional="healthProfessional.attributes" />
      </template>

      <template #aside>
        <UiSpinner v-if="pending" />
        <div
          v-if="healthProfessional?.attributes.hasMembershipBenefits === false && isAMed === false"
          class="mb-4 rounded-lg border border-brick-400 p-4">
          <p class="text-sm text-gray-800">
            Bitte beachte: Bei diesem Arzt ist aktuell kein Mitgliedsrabatt verfügbar.
          </p>
        </div>
        <template v-if="hasCustomBookingUrl">
          <BookingCustomBooking :custom-booking-url="healthProfessional!.attributes.customBookingUrl!" />
        </template>
        <BookingWrapper
          v-else
          :service-id="service!.attributes.serviceId"
          :service-name="serviceName"
          :booking-id="healthProfessional?.attributes.bookingID"
          :department-title="department?.attributes.title"
          :reimbursement="department?.attributes.reimbursement"
          :location="location"
          @change-date="handleChangeDate" />
      </template>
    </NuxtLayout>
  </div>
</template>

<script lang="ts" setup>
import slugify from "@sindresorhus/slugify";
import { useCustomHead } from "~/composables/useCustomHead";
import { useBookingStore } from "~/store/booking";
import { amedKey, membershipBenefitsKey } from "~/types/ProvideKeys";
import type { Department } from "~/types/strapi/api/department/content-types/department/department";
import type { HealthProfessional } from "~/types/strapi/api/health-professional/content-types/health-professional/health-professional";
import type { Payload } from "~/types/strapi/common/schemas-to-ts/Payload";

definePageMeta({
  layout: false,
});

const route = useRoute();
const bookingStore = useBookingStore();

onBeforeUnmount(() => {
  bookingStore.$reset();
});

const { department: departmentSlug, doctor, location: locationSlug, service: serviceSlug } = route.params;
const selectedDate = route.query.selectedDate?.toString() ?? null;
bookingStore.setHealthProfessionalDate(selectedDate);

const locationsStore = useLocationsStore();
const location = await locationsStore.getLocationBySlug(locationSlug.toString());
bookingStore.setLocation(location?.attributes.mobimedId ?? null);

const pending = ref(true);
const [{ data: healthProfessionals }, { data: departments }] = await Promise.all([
  useApiFetch<Payload<HealthProfessional[]>>(`/api/health-professionals?populate=deep,3&filters[slug]=${doctor}`),
  useApiFetch<Payload<Department[]>>(
    `/api/departments?fields[0]=id&fields[1]=title&fields[2]=reimbursement&fields[3]=isAMed&filters[slug]=fachrichtung/${departmentSlug}`,
  ),
]);

if (healthProfessionals.value?.data?.length === 0 || departments.value?.data?.length === 0) {
  throw createError({
    fatal: true,
    statusCode: 404,
    statusMessage: "Page Not Found",
  });
}

const defaultBack = computed(() => {
  const group = service.value?.attributes?.group?.data?.attributes;
  const serviceParams = group ? `${slugify(group.name)}-${group.uuid}` : route.params.service;

  return {
    name: "fachrichtung-department-service",
    params: {
      ...route.params,
      service: serviceParams,
    },
  };
});

async function handleChangeDate() {
  bookingStore.$reset();

  if (selectedDate !== null) {
    await navigateTo({ path: ".", query: { all: "true" } });
  }
}

const healthProfessional = computed<HealthProfessional | undefined>(() => healthProfessionals.value?.data?.[0]);
const department = computed(() => departments.value?.data?.[0]);
const isAMed = computed(() => department.value?.attributes.isAMed ?? false);
provide(amedKey, isAMed.value);
const hasCustomBookingUrl = computed(() => isNotNullOrUndefined(healthProfessional.value?.attributes.customBookingUrl));
const hasMembershipBenefits = computed(() => healthProfessional.value?.attributes.hasMembershipBenefits !== false);
provide(membershipBenefitsKey, hasMembershipBenefits.value);

const serviceId = getServiceUuidFromSlug(serviceSlug.toString());
const service = computed(() => {
  const currentHealthProfessional = healthProfessional.value;

  if (currentHealthProfessional === undefined || serviceId === undefined) {
    return;
  }

  return getServiceFromHealthProfessional(currentHealthProfessional, serviceId);
});

if (!service.value) {
  throw createError({
    fatal: true,
    statusCode: 404,
    statusMessage: "Page Not Found",
  });
}

pending.value = false;

const serviceName = computed(() => {
  if (department.value === undefined || service.value === undefined) {
    return "";
  }

  return getAppointmentNameFromService(service.value.attributes.name);
});

const seo = {
  ...defaultHealthProfessionalMeta(healthProfessional.value?.attributes),
  ...healthProfessional.value?.attributes.seo,
  metaTitle: `${healthProfessional.value?.attributes.fullName} | ${serviceName.value} | ${department.value?.attributes.title}`,
};
useCustomHead(seo);
</script>

<style lang="scss" scoped></style>
