<template>
  <div
    class="flex w-full flex-col justify-between rounded-lg border border-brick-400 px-4 py-2 transition duration-300 ease-in-out"
    :class="{ 'cursor-pointer border-gray-600 bg-white hover:bg-gray-50': hasBenefits }"
    @click="handleOpenMembershipDrawer">
    <template v-if="!isFluVaccinationService && currentPrice">
      <div class="flex justify-between text-sm">
        <p class="text-gray-600">Preis</p>
        <SvgoExternalLink v-if="hasBenefits" class="text-base" />
      </div>
      <div class="flex items-center justify-between text-right font-medium">
        <span v-if="currentPrice" class="text-gray-900">{{ transformToCurrency(currentPrice) }}</span>
        <span
          v-if="hasBenefits === true && hasMembership === false"
          class="ml-2 text-xs leading-tight text-green-600 md:text-sm">
          <span class="hidden sm:inline">spare als Mitglied</span>
          {{ discount }}&nbsp;&#037;
          <span class="sm:hidden">als Mitglied</span>
        </span>
      </div>
    </template>

    <div v-else class="flex items-center justify-between">
      <p v-if="isFluVaccinationServiceLte64">
        <span class="font-medium">{{ transformToCurrency(7) }}&nbsp;</span>
        <span class="text-xs md:text-base">(Selbstbehalt für Impfstoff)</span>
      </p>
      <p v-else-if="isFluVaccinationServiceGte65">
        <span class="font-medium text-gray-900">Kostenlos</span
        ><span class="text-sm md:text-base"
          >, ABER unbedingt eigenen Impfstoff (Fluarix Tetra - adjuvantierter Impfstoff) mitnehmen!</span
        >
      </p>
      <p v-else class="text-xs md:text-base">Preis nach Aufwand</p>
      <SvgoExternalLink v-if="hasBenefits" class="text-base" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { amedKey, membershipBenefitsKey } from "~/types/ProvideKeys";

const props = defineProps<{
  serviceId: number;
  price: string | undefined;
  departmentTitle: string | undefined;
  hasMembership: boolean;
}>();

const hasMembershipBenefits = inject(membershipBenefitsKey);
const isAMed = inject(amedKey);
const hasBenefits = computed(() => hasMembershipBenefits === true && isAMed === false);

const membershipDrawerStore = useMembershipDrawerStore();

const isFluVaccinationServiceLte64 = isFluVaccinationServiceLowerThan65(props.serviceId);
const isFluVaccinationServiceGte65 = isFluVaccinationService65AndOlder(props.serviceId);
const isFluVaccinationService = isFluVaccinationId(props.serviceId);

const discount = computed(() => getDiscountForDepartment(props.departmentTitle));
const fullPrice = computed(() => (props.price ? parseFloat(props.price) : undefined));
const currentPrice = computed(() => {
  if (fullPrice.value === undefined) {
    return;
  }

  if (props.hasMembership === false || isNullOrUndefined(discount.value) || hasBenefits.value === false) {
    return getServicePrice(fullPrice.value);
  }

  return getServicePrice(fullPrice.value, discount.value);
});

function handleOpenMembershipDrawer(): void {
  if (hasBenefits.value === false) {
    return;
  }

  if (isFluVaccinationService === true || currentPrice.value === undefined) {
    membershipDrawerStore.open();
    return;
  }

  membershipDrawerStore.open(fullPrice.value, discount.value, props.departmentTitle);
}
</script>

<style scoped></style>
